// extracted by mini-css-extract-plugin
export var address = "styles-module--address--vknRo";
export var addressLine = "styles-module--address-line--x9XBC";
export var chip = "styles-module--chip--G9WDg";
export var header = "styles-module--header--owg3-";
export var headerRows = "styles-module--headerRows--psm5p";
export var image = "styles-module--image--Jz4B+";
export var imageRound = "styles-module--imageRound--ssRej";
export var imgAndPaymentAction = "styles-module--imgAndPaymentAction--WY5vI";
export var label = "styles-module--label--BdRuc";
export var message = "styles-module--message--ZYVZW";
export var name = "styles-module--name--oF2UL";
export var originalPurchase = "styles-module--originalPurchase--onm1K";
export var paymentAction = "styles-module--paymentAction--1W8xm";
export var paymentHeader = "styles-module--paymentHeader--E-n2Z";
export var product = "styles-module--product--gDKGu";
export var products = "styles-module--products--OoCA4";
export var refunded = "styles-module--refunded--Tp-GD";
export var store = "styles-module--store--YipRW";
export var value = "styles-module--value--piejr";