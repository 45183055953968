import React, { useEffect, useState } from 'react';
import { merge } from 'lodash';

import { ERROR } from 'const';
import { getUrlParams } from 'utils';
import { getPaymentRequest, getReceipt } from 'apiRequests';
import Page from 'site/Page';
import Invoice from 'components/Invoice';

const ViewReceipt = ({ location }) => {
  const { id, key } = getUrlParams(location.search, 'id', 'key');
  // console.log('=== render receipt', { id, key });
  const [invoice, setInvoice] = useState({});
  const [businessName, setBusinessName] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // BE has decided to not provide info to FE in a single request
    Promise.all([getReceipt(id, key), getPaymentRequest(id, key)])
      // deep merge data from the 2 responses
      .then(([receiptData, paymentRequestData]) =>
        merge({}, paymentRequestData, receiptData),
      )
      .then(({ businessName, invoice }) => {
        setInvoice(invoice);
        setBusinessName(businessName);
      })
      .then(() => setIsReady(true))
      .catch((err) => {
        setIsReady(true);
        setError(ERROR.LOADING);
      });
  }, [id, key, setInvoice]);

  return (
    <Page
      title={`View Receipt${businessName ? ` for ${businessName}` : ''}`}
      isLoading={!isReady}
    >
      {/* {console.log({ invoice, isReady })} */}

      <Invoice businessName={businessName} invoice={invoice} error={error} />
    </Page>
  );
};

export default ViewReceipt;
