import React from 'react';
import { string, arrayOf, shape, number, oneOfType } from 'prop-types';
import moment from 'moment';
import cx from 'classnames';

import { getPrice } from 'utils';
import Section from 'site/Section';
import Product from 'components/Product';
import Error from 'components/Error';
import Chip from 'components/Chip';
import refund from '../../../static/refund.svg';
import bag from '../../../static/bag.svg';

import {
  store as storeClass,
  address,
  addressLine,
  name,
  image,
  imageRound,
  header,
  chip,
  products as productsClass,
  product,
  value,
  refunded,
  message as messageClass,
  imgAndPaymentAction,
  paymentAction,
  paymentHeader,
  headerRows,
  originalPurchase,
} from './styles.module.css';

const Invoice = ({ businessName, invoice, error }) => {
  // console.log('+++ Invoice', { invoice, error} });
  const {
    store,
    expireAt,
    totalAmount,
    products,
    customerName,
    createdAt,
    status,
    paidUsing,
    totalRefundAmount,
    refunds,
  } = invoice;

  let statusCheck = status;
  if (
    status?.text === 'refunded' &&
    parseFloat(totalRefundAmount).toFixed(2) > 0 &&
    parseFloat(totalRefundAmount).toFixed(2) < totalAmount
  ) {
    statusCheck.text = 'partially refunded';
  }

  let customerCheck = customerName;
  if (customerName && customerName.length > 25) {
    customerCheck = `${customerName.substr(0, 25)}...`;
  }

  let storeImage = store.image;
  if (store.logoUrl) {
    storeImage = store.logoUrl;
  }

  let storeStyle = image;
  if (store.roundLogo === 1) {
    storeStyle = imageRound;
  }
  return (
    <div>
      {store && (
        <header className={storeClass}>
          {store.image && (
            <img className={storeStyle} alt={businessName} src={storeImage} />
          )}
          {businessName && (
            <h1 className={cx('heading-2', name)}>
              <span className={value}>{businessName}</span>
            </h1>
          )}
          <address className={cx('body-2', address)}>
            <span className={addressLine}>
              <span className={value}>{store.address1}</span>
            </span>
            {' ' /* allows line to wrap between address lines */}
            {
              store.address2 && (
                <span className={value}>
                  <span className={addressLine}>{store.address2}</span>
                  <span className={addressLine}>{` `}</span>
                </span>
              )
              // &&
              // ' ' /* allows line to wrap between address lines */
            }
            <span className={addressLine}>
              {store.city}, {store.state} {store.postalCode}
            </span>
          </address>
        </header>
      )}

      <Error message={error} />

      {totalAmount && (
        <Section>
          <header className={header}>
            <Chip {...statusCheck} className={chip} />
            <h2 className="heading-1">{getPrice(totalAmount)}</h2>
            {expireAt && (
              <p className="body-1">
                Expires after {moment(expireAt).format('MMMM D, YYYY')}
              </p>
            )}
          </header>
          {/* totalRefundAmount */}

          <ul className={productsClass}>
            {products?.map(({ description, imageUrl }) => (
              <li className={product} key={description}>
                <Product description={description} url={imageUrl} />
              </li>
            ))}
          </ul>

          <footer>
            {refunds.length > 0 && (
              <div className={messageClass}>
                <div className={imgAndPaymentAction}>
                  <img alt={businessName} src={refund} />
                  <span className={paymentAction}>Refunds</span>
                </div>
                {refunds?.map(({ returnedAt, totalAmount }) => (
                  <div className={refunded}>
                    <div className={headerRows}>
                      <span className={paymentHeader}>Date</span>
                      <span className={paymentHeader}>Client</span>
                      <span className={paymentHeader}>Total</span>
                      <span className={paymentHeader}>Refunded to</span>
                    </div>
                    <div className={headerRows}>
                      <span className={value}>
                        {' '}
                        {moment(returnedAt).format('MMMM D, YYYY, h:mm a')}
                      </span>
                      <span className={value}> {customerCheck}</span>
                      <span className={value}>
                        {' '}
                        ${parseFloat(totalAmount).toFixed(2)}
                      </span>
                      <span className={value}> {paidUsing}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {totalAmount && (
              <div className={messageClass}>
                <div className={imgAndPaymentAction}>
                  <img alt={businessName} src={bag} />
                  <span className={paymentAction}>
                    {refunds.length > 0 ? 'Original Purchase' : 'Purchase'}
                  </span>
                </div>
                <div className={originalPurchase}>
                  <div className={headerRows}>
                    <span className={paymentHeader}>Date</span>
                    <span className={paymentHeader}>Client</span>
                    <span className={paymentHeader}>Total</span>
                    <span className={paymentHeader}>Paid with</span>
                  </div>
                  <div className={headerRows}>
                    <span className={value}>
                      {' '}
                      {moment(createdAt).format('MMMM D, YYYY')}
                    </span>
                    <span className={value}> {customerCheck}</span>
                    <span className={value}>
                      {' '}
                      ${parseFloat(totalAmount).toFixed(2)}
                    </span>
                    <span className={value}> {paidUsing}</span>
                  </div>
                </div>
              </div>
            )}
          </footer>
        </Section>
      )}
    </div>
  );
};

Invoice.propTypes = {
  businessName: string,
  invoice: shape({
    customerName: string,
    associateName: string,
    store: shape({
      name: string,
      address1: string,
      address2: string,
      city: string,
      state: string,
      postalCode: oneOfType([string, number]),
      image: string,
    }),
    expireAt: string,
    createdAt: string,
    refundCreatedDate: string,
    lastNoteAssociateName: string,
    totalAmount: number,
    totalRefundAmount: number,
    refunds: arrayOf(
      shape({
        associateName: string,
        reason: string,
        refundPaymentRequestId: number,
        returnedAt: string,
        status: string,
        totalAmount: string,
      }),
    ),
    products: arrayOf(
      shape({
        description: string,
        url: string,
      }),
    ),
    status: shape({
      type: string,
      text: string,
    }),
  }),
  error: string,
  message: string,
};

Invoice.defaultProps = {
  businessName: '',
};

export default Invoice;
