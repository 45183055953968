import React from 'react';
import { string, oneOf } from 'prop-types';
import cx from 'classnames';

import {
  chip,
  completed,
  success,
  fail,
  draft,
  warning,
} from './styles.module.css';

export const TYPE_OPTIONS = [
  'completed',
  'success',
  'fail',
  'draft',
  'warning',
];
const TYPE_DEFAULT = TYPE_OPTIONS[0];

const Chip = ({ text, type, className }) =>
  text ? (
    <p
      className={cx(
        chip,
        {
          [completed]: type === 'completed',
          [success]: type === 'success',
          [fail]: type === 'fail',
          [draft]: type === 'draft',
          [warning]: type === 'warning',
        },
        className,
      )}
    >
      {text}
    </p>
  ) : (
    <></>
  );

Chip.propTypes = {
  type: oneOf(TYPE_OPTIONS),
  text: string,
  className: string,
};

Chip.defaultProps = {
  type: TYPE_DEFAULT,
};

export default Chip;
