import React from 'react';
import { string } from 'prop-types';

import defaultImage from 'images/product-default.svg';

import {
  product,
  image,
  description as descriptionClass,
} from './styles.module.css';

const Product = ({ description, url }) => (
  <div className={product}>
    <img
      className={image}
      src={url || defaultImage}
      alt="product image"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = defaultImage;
      }}
    />
    <p className={descriptionClass}>{description}</p>
  </div>
);

Product.propTypes = {
  description: string,
  url: string,
};

export default Product;
