import React from 'react';
import { oneOfType, node, array } from 'prop-types';

import { section } from './styles.module.css';

const Section = ({ children }) => {
  return <section className={section}>{children}</section>;
};

Section.propTypes = {
  children: oneOfType([node, array]),
};

export default Section;
