import React from 'react';
import { arrayOf, string } from 'prop-types';
import cx from 'classnames';

import { error, errorList } from './styles.module.css';

const Error = ({ message, messages }) => {
  const list = message ? [message, ...messages] : messages;

  return list.length ? (
    <ul
      className={cx('body-1', error, {
        [errorList]: list.length > 1,
      })}
    >
      {list.map((msg, i) => (
        <li key={msg + i}>{msg}</li>
      ))}
    </ul>
  ) : null;
};

Error.propTypes = {
  message: string,
  messages: arrayOf(string),
};

Error.defaultProps = {
  messages: [],
};

export default Error;
